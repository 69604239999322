<template >
  <div>
    <topnav></topnav>
    <div class="page-wrap">
      <div class="page-wrap max-small">
        <h1 class="serif">
          {{ $t('Terms & Conditions') }}
        </h1>
        <div
          v-if="$lang === 'en'"
          class="blog-body"
          v-html="legal.body"
        >
        </div>
        <div
          v-if="$lang === 'fr'"
          class="blog-body"
          v-html="legal.bodyFR"
        ></div>
      </div>
    </div>
    <botfooter></botfooter>
  </div>
</template>


<script>
import Botfooter from '@/components/Botfooter.vue';
import Topnav from '@/components/Topnav.vue';
export default {
  components: {
    Topnav,
    Botfooter
  },
  name: "Terms & Conditions",
  computed: {
    legal() {
      const pageID = "64be420ebe111ed729dca39a"
      return this.$local.legal.find(b => b._id === pageID)
    }
  }
}
</script>